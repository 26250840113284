// axios
import axios from 'axios'
// prod
const baseURL = 'https://hub.webaxus.com/server/public/api/'
// dev
// const baseURL = 'http://127.0.0.1:8000/api/'
export default axios.create({
  baseURL,
  headers: {
    // Authorization: `Bearer ${localStorage.getItem('access-token')}`,
    Authorization: `Bearer ${localStorage.getItem('access-token')}`,
    // Authorization: `Bearer FBu0OuSTN6v1UrOlhsLN0LXcTK3Lj5DVV7zeEvRDKc2cCTBDSZ9blZNGZlY7`,
  },
  // You can add your headers here
})
