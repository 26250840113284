import Vue from 'vue'
import Vuex from 'vuex'
// import dependency to handle HTTP request to our back end
// import axios from 'axios'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authModule from './auth/authModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    authModule,
  },
  strict: process.env.DEV,
})
