import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSimpleAlert from 'vue-simple-alert'

import i18n from '@/libs/i18n'
import Vuesax from 'vuesax'
import router from './router'
// import store from './store'
import App from './App.vue'
// axios
import axios from './axios'
// Vuex Store
import store from './store/index'
// Global Components
import './global-components'

// 3rd party plugins
// import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import 'vuesax/dist/vuesax.css' // Vuesax styles
// import 'material-icons/iconfont/material-icons.css'

Vue.use(Vuesax, {
  // options here
})
Vue.prototype.$http = axios

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueSimpleAlert)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
