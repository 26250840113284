export default {
  // login({ commit }, payload) {
  login() {
    // return new Promise((resolve, reject) => {
    //     jwt.login(payload.userDetails.email, payload.userDetails.password)
    //         .then(response => {

    //             // If there's user data in response
    //             if (response.data.userData) {
    //                 // Navigate User to homepage
    // router.push(router.currentRoute.query.to || { name: 'app-task' })
    return 'ounjh hgbbuob'
    //                 // Set accessToken
    //                 localStorage.setItem('accessToken', response.data.accessToken)
    //                 window.location.href = '../dashboard/index';
    //                 // Update user details
    //                 commit('UPDATE_USER_INFO', response.data.userData, { root: true })

    //                 // Set User Role
    //                 // commit('SETROLE', "hyhiuoiunou")
    //                 // console.log(response.data);

    //                 // Set bearer token in axios
    //                 commit('SET_BEARER', response.data.accessToken)

    //                 // localStorage.setItem('currentUser', btoa(response.data.userData.role))
    //                 resolve(response)
    //             } else {
    //                 reject({ message: 'Wrong Email or Password !' })
    //             }

    //         })
    //         .catch(error => { reject(error) })
    // })
  },

}
