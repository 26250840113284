import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import axios from '@/axios'
import ability from '@/libs/acl/ability'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'app-task' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],

})
// function isAuthenticated(){
//   return localStorage.getItem('access-token')
// }
// router.beforeEach( (to,_,next) => {
//   if (
//     // make sure the user is authenticated
//     !isAuthenticated &&
//     to.name !== 'auth-login'
//   ) {
//     // redirect the user to the login page
//     return next({ name: 'auth-login' })
//   } else {
//       return next({name: to.name})
//   }
//   return next()
// })
// router.beforeEach((to, _, next) => {
//   ability.update([
//     {
//       action: 'manage',
//       subject: 'all',
//     },
//   ])
//   if (!localStorage.getItem('access-token') && to.name !== 'auth-login') {
//     // console.log(localStorage.getItem('access-token'))
//     return next({ name: 'auth-login' })
//   }
//   // if(localStorage.getItem('access-token') )
//   return next()
// })
router.beforeEach((to, _, next) => {
  ability.update([
    {
      action: 'manage',
      subject: 'all',
    },
  ])
  if (!localStorage.getItem('access-token') && to.name !== 'auth-login') {
    return next({ name: 'auth-login' })
  }
  return next()
})

// router.beforeEach((to, from, next) => {
//   ability.update([
//         {
//           action: 'manage',
//           subject: 'all',
//         },
//       ])
//   if (to.path === '/login') {
//     return next()
//   }
//   if (localStorage.getItem('access-token') !== null) {
//     return next()
//   }
//   return next({ name: 'auth-login' })
// })

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
