import axios from 'axios'

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  },
  COUNT_INSCRI(state, value) {
    state.countInscri = value
  },
  MESSAGE_SUCCESS(state, value) {
    state.message = value
  },
  // SETROLE(state, value) {
  //     state.currentLoggedInUserRole = value;
  // }
}
